/* eslint-disable no-unused-vars */
import { Button, Form, Input, Modal, notification, Spin, Checkbox, Divider, Avatar } from "antd";
import { UserOutlined } from '@ant-design/icons';
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import BackGround from "../../assets/splashscreen.png";
import BackGroundStrip from "../../assets/Strip.png";
import Logo from "../../assets/Equipment.png";
import Logo2 from "../../assets/bosch-logo.png";
import ForgotPassword from "./ForgotPassword";
import ChnagePasswod from "./ChnagePasswod";
import "./Styles.css";
import axios from "axios";
import { baseUrl } from "../../common/BaseUrl";
import axiosRetry from "axios-retry";
import EulaConfirm from "./EulaConfirm";
import EulaPdf from "../../assets/09102023_EULA_Terms_revised_V1.pdf"
import DataPdf from "../../assets/EM.pdf"
import moment from "moment";

const CheckboxGroup = Checkbox.Group;

const Login = () => {
  const [form] = Form.useForm();
  const [login, setLogin] = useState(true);
  const [forgotPassword, setForgotPasswordn] = useState(false);
  const [resetPassword, setResetPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [verificationCode, setVerificationCode] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const inputRef = useRef();
  const navigate = useNavigate();

  async function fetchPublicKey() {
    try {
      let response = await fetch('/publickey.pem');
      if (!response.ok) {
        throw new Error('Failed to fetch the public key');
      }
      return await response.text();
    } catch (error) {
      console.error('Error fetching public key:', error);
    }
  }

  async function encryptData(data) {
    let publicKeyPem = await fetchPublicKey();
    let pemHeader = "-----BEGIN PUBLIC KEY-----";
    let pemFooter = "-----END PUBLIC KEY-----";
    let pemContents = publicKeyPem.replace(pemHeader, "").replace(pemFooter, "").replace(/\s+/g, "");
    let pemContentsEncoded = Uint8Array.from(atob(pemContents), (char) => char.charCodeAt(0));

    let publicKey = await crypto.subtle.importKey(
      "spki",
      pemContentsEncoded.buffer,
      { name: "RSA-OAEP", hash: "SHA-256", },
      false,
      ["encrypt"]
    );

    let encryptedData = await crypto.subtle.encrypt(
      { name: "RSA-OAEP", },
      publicKey,
      new TextEncoder().encode(data)
    );

    return await btoa(String.fromCharCode(...new Uint8Array(encryptedData)));
  }

  useEffect(() => {
    inputRef.current.focus();
    localStorage.removeItem("logoutTime");
    localStorage.clear();
  }, []);

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onFinish = async (values) => {
    setIsLoading(true);
    axiosRetry(axios, {
      retries: 3,
      retryDelay: (retryCount) => {
        return retryCount * 1000;
      },
      retryCondition: (error) => error.response.status === 504,
    });

    axios.post(`${baseUrl}/login`, {
      email: await encryptData(values?.email),
      password: await encryptData(values?.password),
    }).then((response) => {
      if (response?.status === 200) {
        window.localStorage.setItem("accessToken", response?.data?.accessToken);
        window.localStorage.setItem("refreshToken", response?.data?.refreshToken);
        window.localStorage.setItem("userEmail", values?.email);
        window.localStorage.setItem("userName", response?.data?.profile?.name);
        window.localStorage.setItem("electricParameterVisibility", response?.data?.profile?.ONLY_ELECTRICAL_PARAMETER);
        window.localStorage.setItem("devicetype", response?.data?.profile?.ONLY_ELECTRICAL_PARAMETER === true ? "Equipment" : "Elevator");
        window.localStorage.setItem("profile", JSON.stringify(response?.data?.profile));
        window.localStorage.setItem("expTime", response?.data?.profile?.exp);
        window.localStorage.setItem("role", response?.data?.profile?.["cognito:groups"]);
        window.localStorage.setItem("SHOW_EULA", response?.data?.profile?.SHOW_EULA);
        localStorage.setItem("loginTime", Number(moment().unix()));
        if (!response?.data?.profile?.["custom:eula"]) {
          setIsLoading(false);
          setIsModalOpen(true);
        } else {
          setIsLoading(false);
          navigate("/dashboard");
          notification.success({ description: "Login Successful" });
        }
      } else {
        setIsLoading(false);
      }
    }).catch(() => {
      setIsLoading(false);
      notification.error({ description: `Login Failed` });
    });
  };

  const onForgot = () => {
    setLogin(false);
    setForgotPasswordn(true);
    setResetPassword(false);
  };

  const onResetPassword = () => {
    setLogin(false);
    setForgotPasswordn(false);
    setResetPassword(true);
  };

  const onReset = () => {
    form.resetFields();
  };

  const [checkedConsent, setCheckedConsent] = useState([]);

  let consentList = [
    {
      label: (
        <span style={{ fontWeight: "600" }}>
          You are atleast 18 years old and have read and understood the <a style={{ textDecoration: 'underline' }} href="https://www.bosch.com/corporate-information/">Terms and Conditions</a> / <a style={{ textDecoration: 'underline' }} href={EulaPdf}>EULA</a>  and agree to abide by the same.
        </span>
      ),
      value: 'tce'
    },
    {
      label: (
        <span style={{ fontWeight: "600" }}>
          You have read and understood the <a style={{ textDecoration: 'underline' }} href={DataPdf}>Data Protection Notice</a> and consent to the processing of your personal information for the concerned Purposes.
        </span>
      ),
      value: 'dpnc'
    }
  ];
  const isConsentAccepted = consentList.length === checkedConsent.length;
  const onConsentToogle = (list) => {
    setCheckedConsent(list);
  };

  return (
    <Spin
      spinning={isLoading}
      delay={500}
      tip="Loading"
      size="large"
      style={{ marginTop: "200px", fontSize: "25px", fontWeight: "bold" }}
    >
      <div style={{ display: "block", width: "100%", marginTop: "-4px", position: "absolute", zIndex: "999" }}>
        <img src={BackGroundStrip} width="100%" height="10px" alt="Background Strip" />
      </div>
      <div className="loginscreen" style={{ backgroundImage: `url(${BackGround})`, backgroundSize: "inherit", backgroundRepeat: "no-repeat", height: "100vh", display: "flex", justifyContent: "flex-end", marginTop: "-4px" }}>
        <div style={{ display: "flex", padding: "25px", width: "390px", boxShadow: "0 0px 15px -5px", backdropFilter: "blur(4px)" }}>
          {login && (
            <Form
              form={form}
              name="basic"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              initialValues={{ remember: true }}
              onFinish={onFinish}
              autoComplete="off"
              style={{ alignContent: "center" }}
            >
              <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                <img style={{ position: "absolute", top: "30px", left: "10px" }} src={Logo} height="30px" alt="Logo" />
                <img style={{ position: "absolute", top: "30px", right: "10px" }} src={Logo2} height="27px" alt="Logo" />
              </div>

              <div style={{ display: "flex", justifyContent: "center" }}>
                <Avatar size={128} icon={<UserOutlined />} />
              </div>
              &nbsp;
              <Form.Item name="email" rules={[{ required: true, message: "Please input your email!" }]}>
                <Input style={{ fontWeight: "600" }} placeholder="Enter email" ref={inputRef} />
              </Form.Item>
              <Form.Item name="password" rules={[{ required: true, message: "Please input your password!" }]}>
                <Input.Password style={{ fontWeight: "600" }} placeholder="Enter password" />
              </Form.Item>
              <Form.Item>
                <CheckboxGroup options={consentList} value={checkedConsent} onChange={onConsentToogle} />
              </Form.Item>
              <Form.Item>
                <Button className="btn-grad" disabled={!isConsentAccepted} htmlType="submit" block>Login</Button>
              </Form.Item>
              <Divider />
              <Form.Item>
                <Button type="link" onClick={onForgot} style={{ fontWeight: "600" }}>Forgot password</Button>
                <Button type="link" onClick={onResetPassword} style={{ fontWeight: "600" }}>Change Password</Button>
              </Form.Item>
            </Form>
          )}
          <>
            {forgotPassword && (
              <ForgotPassword style={{ alignContent: "center" }}
                setLogin={setLogin}
                setForgotPasswordn={setForgotPasswordn}
                setResetPassword={setResetPassword}
                setVerificationCode={setVerificationCode}
              />
            )}
            {resetPassword && (
              <ChnagePasswod style={{ alignContent: "center" }}
                setLogin={setLogin}
                setResetPassword={setResetPassword}
                resetPassword={resetPassword}
                setForgotPasswordn={setForgotPasswordn}
                verificationCode={verificationCode}
                setVerificationCode={setVerificationCode}
              />
            )}
          </>
        </div>
      </div>
      <Modal
        bodyStyle={{ height: "400px", overflowY: "scroll" }}
        title="DATA PROTECTION NOTICE"
        open={isModalOpen}
        width={1000}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <EulaConfirm />
      </Modal>
    </Spin>
  );
};

export default Login;
