/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-key */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import {
  Table,
  Spin,
  Modal,
  Col,
  Row,
  notification,
  Pagination,
  Button,
  Divider,
  Checkbox,
} from "antd";
import {
  EyeOutlined,
  EditOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import axios from "axios";
import LoadingSpinner from "../../common/pageLoader/loadingSpinner";
import { AlertBaseUrl } from "../../common/BaseUrl";
import "./Styles.css";
import FilterForm from "../../services/headerFilter/FilterForm";
import RegisterMaintenanceForm from "./RegisterMaintenanceForm";
const Configuration = ({
  userAccess,
  pageLoader,
  alertTripSpan,
  setPageLoader,
  allTableLoader,
  setAllTableLoader,
  setDefaultTabSelection,
  setSelectIndex,
  selectIndex,
  selectFilter,
  setSelectFilter,
  newTableHeight,
}) => {
  const deviceType = window.localStorage.getItem("devicetype");
  const accessToken = window.localStorage.getItem("accessToken");
  const [configurationData, setConfigurationData] = useState();
  const [deleteMaintenanceData, setDeleteMaintenanceData] = useState();
  const [loaderFromEdit, setLoaderFromEdit] = useState(false);
  const [pageLoaderFromEdit, setPageLoaderFromEdit] = useState(false);
  const [openEditMaintenanceModal, setOpenEditMaintenanceModal] =
    useState(false);
  const [typeMaintain, setTypeMaintain] = useState(0);
  const [configPreviewModal, setConfigPreviewModal] = useState(false);
  const [configPreviewData, setConfigPreviewData] = useState();
  const [maintenanceDataEdit, setMaintenanceDataEdit] = useState();
  const [senduserUIID, setSendUserUUID] = useState();
  const [adduserUIID, setAddUserUUID] = useState();
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const [openDeletModal, setOpenDeletModal] = useState(false);
  const [pageNo, setPageNo] = useState();
  const [pageSize, setPageSize] = useState();
  const [paginationCount, setPagiationCount] = useState();
  const [countryName, SetCountryName] = useState();
  const [cityName, SetCityName] = useState();
  const [locationName, SetLocationName] = useState();
  const [buildingName, SetBuildingName] = useState();
  const [elevatorName, SetElevatorName] = useState();
  const [maintenanceFilter, SetMaintenanceFilter] = useState();
  const [filterHeaderapiLoader, setFilterHeaderapiLoader] = useState(false);
  const userProfile = JSON.parse(window.localStorage.getItem("profile"));

  console.log("adduserUIID",maintenanceDataEdit, configurationData)
  //++++++++++++++++++++++++++++++++++++++
  const elevatorUserColumn = [
    {
      title: "Elevator",
      dataIndex: "ELEVATOR_NAME",
      key: "ELEVATOR_NAME",
      align: "center",
    },
    {
      title: "Assign To",
      dataIndex: "ASSIGNED_TO",
      key: "ASSIGNED_TO",
      align: "center",
    },
  ];
  const UserGroupColumn = [
    {
      title: "USER EMAIL",
      dataIndex: "USERID",
      key: "USERID",
      align: "left",
      width: 300,
    },
    {
      title: "EMAIL",
      dataIndex: "EMAIL",
      width: "90px",
      align: "center",
      render: (text, record, index) => (
        <Checkbox key={index} checked={record.EMAIL} disabled={true} />
      ),
    },
    {
      title: "SMS",
      dataIndex: "SMS",
      width: "90px",
      align: "center",
      render: (text, record, index) => (
        <Checkbox key={index} checked={record.SMS} disabled={true} />
      ),
    },
  ];
  //++++++++++++++++++++++++++++++++++++++
  useEffect(() => {
    if(!openEditMaintenanceModal)
    {
    axios
      .post(
        `${AlertBaseUrl}/maintenanceConfigLists`,
        {
          MAINTENANCE_TYPE:
            maintenanceFilter === "Floor Based Maintenance"
              ? 1
              : maintenanceFilter === "Time Based Maintenance"
              ? 0
              : undefined,
          COUNTRY: countryName,
          CITY: cityName,
          LOCATION: locationName,
          STORE_ID: buildingName,
          DEVICE_ID: elevatorName,
          // STATUS: ["PENDING", "OPEN", "INPROGRESS"],
          page: pageNo ? pageNo : 1,
          limit: pageSize ? pageSize : 10,
        },
        { headers: { ["x-access-token"]: accessToken } }
      )
      .then((response) => {
        setConfigurationData(response?.data?.data?.result);
        setPagiationCount(response?.data?.data?.count);
        setPageLoader(false);
      });
    }
  }, [allTableLoader, loaderFromEdit, pageNo, pageSize, filterHeaderapiLoader, openEditMaintenanceModal]);

  useEffect(() => {
    if (senduserUIID) {
      axios
        .post(
          `${AlertBaseUrl}/maintenanceConfigLists/${senduserUIID}`,
          {},
          { headers: { ["x-access-token"]: accessToken } }
        )
        .then((response) => {
          setAddUserUUID(response?.data?.data?.result[0]?.DEVICE_DETAILS);
        });
    }
  }, [senduserUIID, loaderFromEdit]);

  const ConfirmeModal = () => {
    if (deleteMaintenanceData) {
      axios .post( `${AlertBaseUrl}/deleteMaintenanceConfig/${deleteMaintenanceData}`,
          {
            PARENT_ID: userProfile?.["custom:parent_id"],
            TENANT_ID: userProfile?.["custom:tenant_id"]
          },
          { headers: { ["x-access-token"]: accessToken } }
        ).then(() => {
          setAllTableLoader(!allTableLoader);
          setDefaultTabSelection("3");
          setPageLoader(true);
          setOpenDeletModal(false);
          setTimeout(() => { setPageLoader(false); }, 2000);
          notification.success({ description: "Record Deleted", });
        }).catch((error) => {
          setAllTableLoader(!allTableLoader);
          setDefaultTabSelection("3");
          setPageLoader(true);
          setOpenDeletModal(false);
          setTimeout(() => { setPageLoader(false); }, 2000);
          if(error.status == 401){
            notification.error({ description: "Operation unauthorized" });
          }
        });
    }
  };

  const columns = [
    {
      title: "Building",
      dataIndex: "STORE_NAME",
    },
    {
      title: `${deviceType}`,
      dataIndex: "DEVICE_DETAILS",
      render: (DEVICE_DETAILS) =>
        DEVICE_DETAILS?.map((elevator) => <div>{elevator.ELEVATOR_NAME}</div>),
    },
    {
      title: "Type",
      dataIndex: "MAINTENANCE_TYPE",
      render: (MAINTENANCE_TYPE) => {
        if (MAINTENANCE_TYPE == 0) {
          return " Time Based Maintenance";
        } else if (MAINTENANCE_TYPE == 1) {
          return " Floor Based Maintenance";
        } else {
          return "-";
        }
      },
    },
    {
      title: "Description",
      dataIndex: "DESCRIPTION",
    },
    !userAccess
      ? {
          title: "Edit",
          dataIndex: "edit",
          width: "60px",
          render: (text, record, index) => (
            <div style={{ textAlign: "center" }}>
              <Button
                style={{ borderRadius: "10px" }}
                onClick={() => {
                  setTimeout(() => {
                    setOpenEditMaintenanceModal(true);
                  }, 500);
                  setMaintenanceDataEdit(record);
                  setSendUserUUID(record?.MAINTENANCE_CONFIG_ID);
                  setTypeMaintain(
                    record?.MAINTENANCE_TYPE && record?.MAINTENANCE_TYPE === 0
                      ? "TIME"
                      : "FLOOR"
                  );
                }}
              >
                <EditOutlined className="configurationEditIcon" />
              </Button>
            </div>
          ),
        }
      : {
          width: 0,
        },
    !userAccess
      ? {
          title: "Delete",
          dataIndex: "delete",
          width: "60px",
          render: (text, record, index) => (
            <div style={{ textAlign: "center" }}>
              <Button
                onClick={() => {
                  setOpenDeletModal(true);
                  setDeleteMaintenanceData(record?.MAINTENANCE_CONFIG_ID);
                }}
                style={{ borderRadius: "10px" }}
              >
                <DeleteOutlined className="configurationEditIcon" />
              </Button>
            </div>
          ),
        }
      : {
          width: 0,
        },
    {
      title: "Preview",
      dataIndex: "preview",
      width: "80px",
      render: (text, record, index) => (
        <div style={{ textAlign: "center" }}>
          <Button
            style={{ borderRadius: "10px" }}
            onClick={() => {
              setSendUserUUID(record?.MAINTENANCE_CONFIG_ID);
              setConfigPreviewModal(true);
              setConfigPreviewData(record);
            }}
          >
            <EyeOutlined className="configurationEditIcon" />
          </Button>
        </div>
      ),
    },
  ];
  const handleTableChange = (pagination) => {
    setPagination(pagination);
  };

  return (
    <div>
      <div style={{ marginTop: "-25px" }}>
        <FilterForm
          filtertype="PeriodicConfiguration"
          SetCountryName={SetCountryName}
          SetCityName={SetCityName}
          SetLocationName={SetLocationName}
          SetBuildingName={SetBuildingName}
          SetElevatorName={SetElevatorName}
          setFilterHeaderapiLoader={setFilterHeaderapiLoader}
          filterHeaderapiLoader={filterHeaderapiLoader}
          SetMaintenanceFilter={SetMaintenanceFilter}
        />
      </div>
      {pageLoaderFromEdit ? (
        <LoadingSpinner />
      ) : (
        <>
          <Table
            style={{ marginTop: "-20px" }}
            className="MainTable"
            rowKey="id"
            pagination={false}
            onChange={handleTableChange}
            columns={columns}
            dataSource={configurationData}
            scroll={{ y: newTableHeight }}
            size="small"
            loading={{
              indicator: (
                <div>
                  <Spin />
                </div>
              ),
              spinning: !configurationData,
            }}
          />
          <div style={{ float: "right" }}>
            <Pagination
              defaultCurrent={1}
              total={paginationCount}
              onChange={(page, pageSize) => {
                setPageNo(page);
                setPageSize(pageSize);
              }}
            />
          </div>
        </>
      )}
      <Modal
        width={1000}
        footer={null}
        destroyOnClose={true}
        title="Edit Maintenance Configuration"
        open={openEditMaintenanceModal}
        onOk={() => setOpenEditMaintenanceModal(false)}
        onCancel={() => {
          setOpenEditMaintenanceModal(false);
        }}
      >
        <div
          style={{
            height: "30vw",
            overflowY: "auto",
          }}
        >
        <RegisterMaintenanceForm
        maintenanceDataEdit={maintenanceDataEdit}
        alertTripSpan={alertTripSpan}
        setOpenEditMaintenanceModal={setOpenEditMaintenanceModal}
        setPageLoader={setPageLoader}
        pageLoader={pageLoader}
        setDefaultTabSelection={setDefaultTabSelection}
        setLoaderFromEdit={setLoaderFromEdit}
        loaderFromEdit={loaderFromEdit}
        setPageLoaderFromEdit={setPageLoaderFromEdit}
        adduserUIID={adduserUIID}
        setSelectIndex={setSelectIndex}
        selectIndex={selectIndex}
        selectFilter={selectFilter}
        setSelectFilter={setSelectFilter}
        setTypeMaintain={setTypeMaintain}
        typeMaintain={typeMaintain}
        />
     
        </div>
      </Modal>
      <Modal
        width={1000}
        destroyOnClose={true}
        open={configPreviewModal}
        onOk={() => setConfigPreviewModal(false)}
        onCancel={() => {
          setConfigPreviewModal(false);
        }}
        footer={null}
      >
        <Divider style={{ fontSize: "18px", fontWeight: "bold" }}>
          Maintenance Configuration
        </Divider>
        <div style={{ height: "60vh", overflowY: "auto" }}>
          <Row>
            <Col xs={12} sm={12}>
              <Row>
                <Col xs={12} sm={12} className="previewModalText">
                  COUNTRY
                </Col>
                <Col xs={12} sm={12} className="previewModalresponce">
                  {configPreviewData?.COUNTRY}
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={12} className="previewModalText">
                  LOCATION
                </Col>
                <Col xs={12} sm={12} className="previewModalresponce">
                  {configPreviewData?.LOCATION}
                </Col>
              </Row>
            </Col>
            <Col xs={12} sm={12}>
              <Row>
                <Col xs={8} sm={8} className="previewModalText">
                  CITY
                </Col>
                <Col xs={16} sm={16} className="previewModalresponce">
                  {configPreviewData?.CITY}
                </Col>
              </Row>
              <Row>
                <Col xs={8} sm={8} className="previewModalText">
                  BUILDING
                </Col>
                <Col xs={16} sm={16} className="previewModalresponce">
                  {configPreviewData?.STORE_NAME}
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Row>
                <Col xs={12} sm={12} className="previewModalText">
                  CREATED ON
                </Col>
                <Col xs={12} sm={12} className="previewModalresponce">
                  {configPreviewData?.CREATED_ON?.slice(0, 10)}
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <Row>
                <Col xs={8} sm={8} className="previewModalText">
                  CREATED BY
                </Col>
                <Col xs={16} sm={16} className="previewModalresponce">
                  {configPreviewData?.CREATED_BY}
                </Col>
              </Row>
            </Col>
          </Row>
          <div
            style={{
              backgroundColor: "#f5f3ed",
              padding: "10px",
              borderRadius: "10px",
              marginTop: "15px",
            }}
          >
            <Table
              className="MainTable"
              columns={elevatorUserColumn}
              dataSource={adduserUIID}
              pagination={false}
              size="small"
            />
          </div>
          <div
            style={{
              backgroundColor: "#f5f3ed",
              padding: "10px",
              borderRadius: "10px",
              marginTop: "15px",
            }}
          >
            {configPreviewData?.MAINTENANCE_TYPE === 1 ? (
              <div className="previewModalresponceTYPE">
                MAINTENANCE BASED ON FLOOR TRAVELLED
              </div>
            ) : (
              <div className="previewModalresponceTYPE">
                MAINTENANCE BASED ON TIME DURATION
              </div>
            )}
            <Row>
              <Col xs={12} sm={12}>
                <Row>
                  <Col xs={12} sm={12} className="previewModalText">
                    {configPreviewData?.MAINTENANCE_TYPE === 1
                      ? "THRESHOLD LIMIT"
                      : "GENERATION DAY"}
                  </Col>
                  <Col xs={12} sm={12} className="previewModalresponce">
                    {configPreviewData?.MAINTENANCE_TYPE === 1
                      ? configPreviewData?.MAINTENANCE_DEFINITION
                          ?.THRESHOLD_LIMIT
                      : configPreviewData?.MAINTENANCE_DEFINITION
                          ?.GENERATION_DAY}
                  </Col>
                </Row>
                <Row>
                  {configPreviewData?.MAINTENANCE_TYPE === 0 ? (
                    <Col xs={12} sm={12} className="previewModalText">
                      RECURRANCE
                    </Col>
                  ) : (
                    ""
                  )}
                  <Col xs={12} sm={12} className="previewModalresponce">
                    {configPreviewData?.MAINTENANCE_DEFINITION?.RECURRANCE}
                  </Col>
                </Row>
              </Col>
              <Col xs={12} sm={12}>
                <Row>
                  <Col xs={12} sm={12} className="previewModalText">
                    THRESHOLD PERIOD
                  </Col>
                  <Col xs={12} sm={12} className="previewModalresponce">
                    {configPreviewData?.MAINTENANCE_DEFINITION?.THRESHOLD_PERIOD
                      ? configPreviewData?.MAINTENANCE_DEFINITION
                          ?.THRESHOLD_PERIOD
                      : "-"}
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          <Divider style={{ fontSize: "18px", fontWeight: "bold" }}>
            User Notify
          </Divider>
          <div
            style={{
              backgroundColor: "#f5f3ed",
              padding: "10px",
              borderRadius: "10px",
              marginTop: "15px",
            }}
          >
            <Table
              className="MainTable"
              columns={UserGroupColumn}
              dataSource={configPreviewData?.RESPONSIBLE_CRITERIA}
              pagination={false}
              size="small"
            />
          </div>
        </div>
      </Modal>
      <Modal
        open={openDeletModal}
        onOk={ConfirmeModal}
        onCancel={() => setOpenDeletModal(false)}
        okText="Confirm "
        cancelText="Cancel"
      >
        <div className="confirmModalExc">
          <ExclamationCircleOutlined />
        </div>
        <h3 className="confirmYText">
          ARE YOU SURE YOU WANT TO REMOVE THIS RECORD..?
        </h3>
      </Modal>
    </div>
  );
};
export default Configuration;
