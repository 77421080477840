/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import {
  Modal,
  Spin,
  notification,
  Tag,
  Table,
  Pagination,
  Button,
} from "antd";
import React, { useState, useEffect } from "react";
import {
  EyeOutlined,
  EditOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { AlertBaseUrl} from "../../common/BaseUrl";
import "./Styles.css";
import NewEventModal from "./NewEventModal";
import axios from "axios";
import CurrentAlertPreviewModal from "./CurrentAlertPreviewModal";
import FilterForm from "../../services/headerFilter/FilterForm";

const CurrentEventsTab = ({
  setSelectTab,
  selectTab,
  listAPI,
  setSelectFilter,
  selectFilter,
  userAccess,
}) => {

  const [alertList, setAlertList] = useState();
  const accessToken = window.localStorage.getItem("accessToken");
  const deviceType = window.localStorage.getItem("devicetype");
  const [eventModal, setEventModal] = useState(false);
  const [previewModal, setPreviewModal] = useState(false);
  const [previewRowData, setPreviewRowData] = useState();
  const [editRowData, setEditRowData] = useState();
  const [tableLoad, setTableLoad] = useState(false);
  const [newtableLoad, setNewTableLoad] = useState(false);
  const [deleteRowData, setDeleteRowData] = useState();
  const [pageLoader, setPageLoader] = useState(false);
  const [openalertDeletModal, setOpenalertDeletModal] = useState(false);
  const [pageNo, setPageNo] = useState();
  const [pageSize, setPageSize] = useState();
  const [paginationCount, setPagiationCount] = useState();
  const [newTableHeight,setNewTableHeight] = useState()
  const [countryName, SetCountryName] = useState();
  const [cityName, SetCityName] = useState();
  const [locationName, SetLocationName] = useState();
  const [buildingName, SetBuildingName] = useState();
  const [elevatorName, SetElevatorName] = useState();
  const [alertTypeName, SetAlertTypeName] = useState();
  const [priorityFilter, SetPriorityFilter] = useState();
  const [filterHeaderapiLoader, setFilterHeaderapiLoader] = useState(false);
  const divHeight = window.localStorage.getItem("SpaceHeight");
  const userProfile = JSON.parse(window.localStorage.getItem("profile"));

  useEffect(()=>{
    setNewTableHeight(divHeight-170)
  },[divHeight])
  //+++++++++++++++++++++++++++++++++++++++++++++++++++++

  const getAlertList=()=>{
    axios
    .post(
      `${AlertBaseUrl}/lists`,

      {
        PRIORITY: priorityFilter ? priorityFilter : undefined,
        PARAMETER:
              alertTypeName === "IDLE TIME" ||
              alertTypeName === "OPERATION OUTSIDE WORKING HOURS" ||
              alertTypeName === "NO OF TRIPS" ||
              alertTypeName === "MAINTENANCE ON FLOOR" ||
              alertTypeName === "MAINTENANCE ON TIME" ||
              alertTypeName === "OFFLINE/LOST COMMUNICATION/NO POWER"||
              alertTypeName === "MANUAL TAMPERING" ||
              alertTypeName === "POWER SHUTDOWN" ||
              alertTypeName === "ELEVATOR ONLINE" ||
              alertTypeName === "LIFT STUCK"
                ? 101
                : alertTypeName === "OVER CURRENT" ||
                  alertTypeName === "OVER VOLTAGE" ||
                  alertTypeName === "PF LAG" ||
                  alertTypeName === "REACTIVE POWER" ||
                  alertTypeName === "V-THD" ||
                  alertTypeName === "POWER DEMAND"||
                  alertTypeName === "UNDER VOLTAGE" 
                ? 0
                : alertTypeName === "TARGET BREACH"
                ? 1
                : undefined,
        CRITERIA:
              alertTypeName === "NO OF TRIPS" || alertTypeName === "OVER VOLTAGE" ? 0
                : alertTypeName === "MAINTENANCE ON TIME" || alertTypeName === "OVER CURRENT" ? 1
                : alertTypeName === "MAINTENANCE ON FLOOR" || alertTypeName === "REACTIVE POWER" ? 2
                : alertTypeName === "IDLE TIME" || alertTypeName === "PF LAG" ? 3
                : alertTypeName === "OPERATION OUTSIDE WORKING HOURS" || alertTypeName === "V-THD" ? 4
                : alertTypeName === "OFFLINE/LOST COMMUNICATION/NO POWER" || alertTypeName === "POWER DEMAND" ? 5
                : alertTypeName === "MANUAL TAMPERING" || alertTypeName === "UNDER VOLTAGE" ? 6
                : alertTypeName === "POWER SHUTDOWN" ? 7 
                : alertTypeName === "ELEVATOR ONLINE" ? 8 
                : alertTypeName === "LIFT STUCK" ? 9 
                : undefined,
        COUNTRY: countryName,
        CITY: cityName,
        LOCATION: locationName,
        STORE_ID: buildingName,
        DEVICE_ID: elevatorName,
        page: pageNo ? pageNo : 1,
        limit: pageSize ? pageSize : 10,
      },
      {
        headers: { ["x-access-token"]: accessToken },
        ["x-offset"]: -new Date().getTimezoneOffset(),
      }
    )
    .then((Response) => {
      setAlertList(Response?.data?.data?.result);
      setPagiationCount(Response?.data?.data?.count);
    });
  }
  console.log("alertListalertList",alertList)
  useEffect(() => {
    getAlertList();
  }, [tableLoad, listAPI, pageNo, pageSize, filterHeaderapiLoader]);

  const columns = [
    {
      title: "S/n",
      dataIndex: "SN",
      width: "50px",
      align: "center",
    },
    {
      title: "Alert Type",
      dataIndex: "ALERT_TYPE",
      key: "eventType",
      width: "140px",
    },
    {
      title: "Priority",
      dataIndex: "PRIORITY",
      key: "priority",
      align: "center",
      width: "90px",
      render: (PRIORITY) => {
        if (PRIORITY?.toUpperCase() === "HIGH") {
          return (
            <Tag
              className="ProprityTag"
              color="#ea4758"
            >
              High
            </Tag>
          );
        } else if (PRIORITY?.toUpperCase() === "MEDIUM") {
          return (
            <Tag
              color="#caca57"
              className="ProprityTag"
            >
              Medium
            </Tag>
          );
        } else if (PRIORITY?.toUpperCase() === "LOW") {
          return (
            <Tag
              color="#58cf64"
              className="ProprityTag"
            >
              Low
            </Tag>
          );
        } else {
          return (
            <Tag
              className="ProprityTag"
              color="#f50"
            >
              NF
            </Tag>
          );
        }
      },
    },
    {
      title: "Description",
      dataIndex: "DESCRIPTION",
      key: "description",
      align: "center",
      width: "140px",
    },
    {
      title: "Country",
      dataIndex: "COUNTRY",
      key: "country",
      align: "center",
      width: "90px",
    },
    {
      title: "City",
      dataIndex: "CITY",
      key: "city",
      align: "center",
      width: "100px",
    },
    {
      title: "Building",
      dataIndex: "STORE_NAME",
      key: "store",
      align: "center",
      width: "100px",
    },
    {
      title: `${deviceType} Name`,
      dataIndex: "DEVICE_NAME",
      key: "elevatorName",
      align: "center",
      width: "120px",
    },
    {
      title: "Created On",
      dataIndex: "CREATE_TIME",
      key: "timestamp",
      align: "center",
      width: "90px",
    },
    !userAccess
      ? {
          title: "Edit",
          dataIndex: "action",
          width: "50px",
          align: "center",
          render: (text, record, index) => (
            <div style={{ textAlign: "center" }}>
            <Button 
            style={{ borderRadius: "10px" }}
            onClick={() => {
              
              setEditRowData(record);
              setEventModal(true);
            }}>
            <EditOutlined
            className="configurationEditIcon"
              
            />
            </Button>
             
            </div>
          ),
        }
      : {
          width: 0,
        },
    !userAccess
      ? {
          title: "Delete",
          dataIndex: "action",
          width: "60px",
          align: "center",
          render: (text, record, index) => (
            <div style={{ textAlign: "center" }}>
            <Button
            style={{ borderRadius: "10px" }}
             onClick={() => {
              setOpenalertDeletModal(true);
              setDeleteRowData(record);
            }}>
            <DeleteOutlined
              className="configurationEditIcon"
                
              />
              </Button>
              
            </div>
          ),
        }
      : {
          width: 0,
        },
    {
      title: "Preview",
      dataIndex: "action",
      width: "70px",
      align: "center",
      render: (text, record, index) => (
        <div style={{ textAlign: "center" }}>
        <Button 
        style={{ borderRadius: "10px" }}
         onClick={() => {
          setPreviewRowData(record);
          setPreviewModal(true);
        }}>
        <EyeOutlined
        className="configurationEditIcon"
        />
        </Button>
         
        </div>
      ),
    },
  ];
  if (alertList) {
    for (let i = 0; i < alertList.length; i++) {
      let obj = alertList[i];

      obj["ALERT_TYPE"] =
        obj?.CRITERIA === 0 && obj?.PARAMETER === 101 ? "NO OF TRIPS"
          : obj?.CRITERIA === 2 && obj?.PARAMETER === 101 ? "MAINTENANCE ON FLOOR"
          : obj?.CRITERIA === 1 && obj?.PARAMETER === 101 ? "MAINTENANCE ON TIME"
          : obj?.CRITERIA === 3 && obj?.PARAMETER === 101 ? "IDLE TIME"
          : obj?.CRITERIA === 4 && obj?.PARAMETER === 101 ? "OPERATION OUTSIDE WORKING HOURS"
          : obj?.CRITERIA === 5 && obj?.PARAMETER === 101 ? "OFFLINE/LOST COMMUNICATION/NO POWER"
          : obj?.CRITERIA === 6 && obj?.PARAMETER === 101 ? "LIFT STUCK"
          : obj?.CRITERIA === 7 && obj?.PARAMETER === 101 ? "POWER SHUTDOWN"
          : obj?.CRITERIA === 8 && obj?.PARAMETER === 101 ? "ELEVATOR ONLINE"
          : obj?.CRITERIA === 9 && obj?.PARAMETER === 101 ? "LIFT STUCK"
          : obj?.CRITERIA === 0 && obj?.PARAMETER === 0 ? "OVER VOLTAGE"
          : obj?.CRITERIA === 1 && obj?.PARAMETER === 0 ? "OVER CURRENT"
          : obj?.CRITERIA === 2 && obj?.PARAMETER === 0 ? "REACTIVE POWER"
          : obj?.CRITERIA === 3 && obj?.PARAMETER === 0 ? "PF LAG"
          : obj?.CRITERIA === 4 && obj?.PARAMETER === 0 ? "V-THD"
          : obj?.CRITERIA === 5 && obj?.PARAMETER === 0 ? "POWER DEMAND"
          : obj?.CRITERIA === 6 && obj?.PARAMETER === 0 ? "UNDER VOLTAGE"
          : obj?.CRITERIA === 5 && "TARGET BREACH";
      obj["CREATE_TIME"] = obj?.CREATED_ON?.slice(0, 10);
      obj["SN"] = i + 1;
    }
  }

  // function exportToCsv() {
  //   const data = alertList.map((row) => {
  //     const rowData = {};
  //     columns.forEach((column) => {
  //       if (column.dataIndex) {
  //         rowData[column.dataIndex] = row[column.dataIndex];
  //       }
  //     });
  //     return rowData;
  //   });

  //   const header = columns
  //     .map((column) => column.title || column.dataIndex)
  //     .join(",");

  //   const csvData =
  //     header +
  //     "\n" +
  //     data.map((row) => Object.values(row).join(",")).join("\n");
  //   const link = document.createElement("a");
  //   link.href = "data:text/csv;charset=utf-8," + encodeURIComponent(csvData);
  //   link.download = "Alerts.csv";
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  // }

  const hideModal = () => {
    setOpenalertDeletModal(false);
  };

  const ConfirmeModal = () => {
    if (deleteRowData) {
      setPageLoader(true);
      axios.post(`${AlertBaseUrl}/delete/${deleteRowData?.ALERT_ID}`,
          {
            PARENT_ID: userProfile?.["custom:parent_id"],
            TENANT_ID: userProfile?.["custom:tenant_id"]
          },
          { headers: { ["x-access-token"]: accessToken } }
        ).then((Response) => {
          setPageLoader(false);
          setOpenalertDeletModal(false);
          setTableLoad(!tableLoad);
          setNewTableLoad(!newtableLoad);
          if (Response?.data?.data?.message === "1 record is inactive") {
            notification.success({ description: "Deleted", });
          }
        }).catch((error) => {
          setPageLoader(false);
          setOpenalertDeletModal(false);
          setTableLoad(!tableLoad);
          setNewTableLoad(!newtableLoad);
          if(error.status == 401){
            notification.error({ description: "Operation unauthorized" });
          }
        });
    }
  };
  return (
    <div 
    style={{ width: "99.5vw" }}
    >
      {/* <Popover content="Download in CSV" placement="topRight">
        <Button
        className="ExportButton"
          onClick={exportToCsv}
        >
          EXPORT&nbsp;
          <DownloadOutlined />
        </Button>
      </Popover> */}
      <div className="CurrenttabfromDiv" style={{marginTop:"-25px"}}
      >
      <FilterForm
        filtertype="alertNotificationcurrenttab"
        SetCountryName={SetCountryName}
        SetCityName={SetCityName}
        SetLocationName={SetLocationName}
        SetBuildingName={SetBuildingName}
        SetElevatorName={SetElevatorName}
        SetAlertTypeName={SetAlertTypeName}
        SetPriorityFilter={SetPriorityFilter}
        setFilterHeaderapiLoader={setFilterHeaderapiLoader}
        filterHeaderapiLoader={filterHeaderapiLoader}
        alertTypeName={alertTypeName}
      />
      </div>
      <Spin spinning={pageLoader} tip="Deleting Data..." size="large">
        <Table
        style={{marginTop: "-25px"}}
          className="MainTable"
          // size="middle"
          columns={columns}
          dataSource={alertList}
          pagination={false}
          scroll={{ y: newTableHeight }}
          loading={{
            indicator: (
              <div>
                <Spin />
              </div>
            ),
            spinning: !alertList,
          }}
        />
      </Spin>
      <div style={{ float: "right",height:"30px", marginTop:"4px"}}>
        <Pagination
          defaultCurrent={1}
          total={paginationCount}
          onChange={(page, pageSize) => {
            setPageNo(page);
            setPageSize(pageSize);
          }}
        />
      </div>

      {eventModal&&<Modal
        open={eventModal}
        destroyOnClose={true}
        onOk={() => setEventModal(false)}
        onCancel={() => {
          setEventModal(false);
          setEditRowData({});
        }}
        width={800}
        footer={null}
      >
        <div className="EventModalCurrentab">
          <NewEventModal
            setSelectFilter={setSelectFilter}
            selectFilter={selectFilter}
            setSelectTab={setSelectTab}
            selectTab={selectTab}
            setEventModal={setEventModal}
            editRowData={editRowData}
            getAlertList={getAlertList}
            setTableLoad={setTableLoad}
            setNewTableLoad={setNewTableLoad}
            newtableLoad={newtableLoad}
            setEditRowData={setEditRowData}
            tableLoad={tableLoad}
          />
        </div>
      </Modal>}

      <Modal
        open={previewModal}
        onOk={() => setPreviewModal(false)}
        onCancel={() => {
          setPreviewModal(false);
        }}
        destroyOnClose={true}
        width={1000}
        footer={null}
      >
        <CurrentAlertPreviewModal previewRowData={previewRowData} />
      </Modal>
      <Modal
        open={openalertDeletModal}
        onOk={ConfirmeModal}
        onCancel={hideModal}
        okText="Confirm "
        cancelText="Cancel"
      >
        <div className="confirmModalExc"
        >
          <ExclamationCircleOutlined />
        </div>
        <h3 className="confirmYText">
          ARE YOU SURE YOU WANT TO REMOVE THIS ALERT?
        </h3>
      </Modal>
    </div>
  );
};

export default CurrentEventsTab;
